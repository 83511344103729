.gallery-container {
  display: flex;
  justify-content:center;
  margin: 0 80px 0 80px;
}

.header {
  text-align: center;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-weight: 600;
  font-size: 1.6rem;
  margin-bottom: 2.5rem;
}

.image-gallery-content {
  background-color:#356A7C;
  padding: 1.5rem;
  margin:0 auto;
}

.gallery {
  flex-grow: 1;
  flex-shrink:1;
}

/*@media (max-width:1080px) {
  .image-gallery-content {
    max-width: 53rem;
  }
}

@media (max-width:950px) {
  .image-gallery-content {
    max-width:48rem;
  }
}

@media (max-width:768px) {
  .image-gallery-content {
    max-width:40rem;
  }
}


@media (max-width:700px) {
  .image-gallery-content {
    max-width: 40rem;
  }
}

@media (max-width:680px) {
  .image-gallery-content {
    max-width:35rem;
  }
}

@media (max-width:580px) {
  .image-gallery-content {
    max-width:30rem;
  }
}

@media (max-width:560px) {
  .image-gallery-content {
    max-width: 33rem;
  }
}

@media (max-width:480px) {
  .image-gallery-content {
    max-width:25rem;
  }
}

@media (max-width:414px) {
  .image-gallery-content {
    max-width: 25rem;
  }
}

@media (max-width:380px) {
  .image-gallery-content {
    max-width:20rem;
  }
}

@media (max-width:375px) {
  .image-gallery-content {
    max-width: 22rem;
  }
}

@media (max-width:320px) {
  .image-gallery-content {
    max-width: 19rem;
  }
}

@media (max-width:280px) {
  .image-gallery-content {
    max-width:15rem;
  }
}*/